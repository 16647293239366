.wmp-title-container{
    position: absolute;
    width: 100%;
    height: 60px;
    top: 0%;
    text-align: left;
    cursor: pointer;
}

.wmp-title{
    text-shadow: 0 0 2px rgba(0,0,0,.5);
    position: absolute;
    padding-left: 17px;
    padding-top: 12px;
    font-family: "YouTube Noto",Roboto,Arial,Helvetica,sans-serif;
    color: #eee;
    text-align: left;
    direction: ltr;
    font-size: 18px;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    white-space: nowrap;
    word-wrap: normal;
    cursor: pointer;
    z-index:10;
    color: inherit;
    text-decoration: none;
    -moz-transition: color .1s cubic-bezier(0.0,0.0,0.2,1);
    -webkit-transition: color .1s cubic-bezier(0.0,0.0,0.2,1);
    transition: color .1s cubic-bezier(0.0,0.0,0.2,1);
    outline: 0;
}

.light-grey-to-white { 
    color: #e4e5e8; 
}
.light-grey-to-white:hover { 
    color: rgba(255, 255, 255, 1); 
}

.wmp-top-shading{
    position: absolute;
    top:0px;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(to bottom, black, transparent);
    opacity:0.25;
    z-index:0;
}