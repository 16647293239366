.wmp-tool-button{
    display: table-cell;
    width: 38px;
    text-align: center;
    height: 36px;
    vertical-align: middle;
    padding-right: 4px;
}

svg:hover .wmp-tool-button-logo{
    fill: white;
}

.light-grey-to-white { 
    color: #e4e5e8; 
}
.light-grey-to-white:hover { 
    color: rgba(255, 255, 255, 1); 
}