.wmp-tool-button.button-time{
    margin-right:2px;
    padding-left: 5px;
    padding-bottom: 0px;
}

.wmp-time-display{
    width: auto;
    font-family: "YouTube Noto",Roboto,Arial,Helvetica,sans-serif;
    color: #eee;
    text-align: left;
    direction: ltr;
    font-size: 14px;
    line-height: 1.3;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    vertical-align: middle;
}

.wmp-time-positionning{
    padding-top: 2px;
}
