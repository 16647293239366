.wmp-volume-slider{
    width: 52px;
}

.wmp-volume-slider-total-bar{
    height: 3px;
    width: 52px;
    margin-top: -1px;
    max-width: 52px;
    display: block;
    position: absolute;
}

.wmp-volume-slider-level-bar{
    height: 100%;
    background-color:white;
    display: block;
    position: absolute;
}

.wmp-volume-slider-left-bar{
    height: 100%;
    background-color: rgba(255,255,255,.2);
    display: block;
}

.wmp-volume-slider-scrubber-button{
    position: relative;
    height: 13px;
    width: 13px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    margin-top: -8px;
}