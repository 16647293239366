.wmp-container{
    background-color: black;
    color: white;
    position: relative;
    margin-bottom: 4px;
        -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
           -khtml-user-select: none; /* Konqueror HTML */
             -moz-user-select: none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer/Edge */
                  user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome and Opera */
}

img {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
}
