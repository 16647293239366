.wmp-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 64px;
    margin-left: -32px;
    z-index: 18;
    pointer-events: none
  }
  
  .wmp-spinner-container {
    pointer-events: none;
    position: absolute;
    width: 100%;
    padding-bottom: 100%;
    top: 50%;
    left: 50%;
    margin-top: -50%;
    margin-left: -50%;
    animation: wmp-spinner-linspin 1568.23529647ms linear infinite;
  }
  
  .wmp-spinner-rotator {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: wmp-spinner-easespin 5332ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both
  }
  
  .wmp-spinner-left {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden
  }
  
  .wmp-spinner-right {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden
  }
  
  .wmp-spinner-left {
    right: 49%
  }
  
  .wmp-spinner-right {
    left: 49%
  }
  
  .wmp-spinner-circle {
    box-sizing: border-box;
    position: absolute;
    width: 200%;
    height: 100%;
    border-style: solid;
    border-color: #ddd #ddd transparent;
    border-radius: 50%;
    border-width: 6px
  }
  
  .wmp-spinner-left .wmp-spinner-circle {
    left: 0;
    right: -100%;
    border-right-color: transparent;
    animation: wmp-spinner-left-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  }
  
  .wmp-spinner-right .wmp-spinner-circle {
    left: -100%;
    right: 0;
    border-left-color: transparent;
    animation: wmp-right-spin 1333ms cubic-bezier(0.4, 0.0, 0.2, 1) infinite both;
  }
  
  @keyframes wmp-spinner-linspin {
    to {
      transform: rotate(360deg)
    }
  }
  
  @keyframes wmp-spinner-easespin {
    12.5% {
      transform: rotate(135deg)
    }
    25% {
      transform: rotate(270deg)
    }
    37.5% {
      transform: rotate(405deg)
    }
    50% {
      transform: rotate(540deg)
    }
    62.5% {
      transform: rotate(675deg)
    }
    75% {
      transform: rotate(810deg)
    }
    87.5% {
      transform: rotate(945deg)
    }
    to {
      transform: rotate(1080deg)
    }
  }
  
  @keyframes wmp-spinner-left-spin {
    0% {
      transform: rotate(130deg)
    }
    50% {
      transform: rotate(-5deg)
    }
    to {
      transform: rotate(130deg)
    }
  }
  
  @keyframes wmp-right-spin {
    0% {
      transform: rotate(-130deg)
    }
    50% {
      transform: rotate(5deg)
    }
    to {
      transform: rotate(-130deg)
    }
  }